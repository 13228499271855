import React, {Component, Fragment} from "react";
import FormValidator from "../../../services/FormValidator";
import Input from "../../Common/Input";
import GlobalConfig from "../../../globalConfig/globalConfig";
import {commonService} from "../../../storeManager/common/services";
import {PATHS, MESSAGES, INPUT_MAX_LENGTH, POLICY_URL} from './../../../constants';
import Loader from "../../Loader";
import {withRouter} from 'react-router-dom'
import {MODALS} from "../../../storeManager/modal/constants";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../../storeManager/modal/actions";
import ReCaptcha from "../../Common/GoogleCaptcha/googleCaptcha";
import { interceptedData } from "../../../services/commonUtils";
// import Modal from "../../Common/Modal";


class Registration extends Component {
    state = {
        registrationForm: {
            firmName: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'firm'],
                labelConfig: {
                    label: 'Firm Name',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Firm Name',
                    maxLength: INPUT_MAX_LENGTH.LENGTH_ONE_FIFTY,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
            },
            firmMobileNumber: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'phone'],
                labelConfig: {
                    label: 'Firm Mobile Number',
                    classes: ['floating-label'],
                    tooltip: MESSAGES.PHONE_TOOLTIP
                },
                elementType: 'input',
                elementConfig: {
                    type: 'tel',
                    placeholder: 'Firm Mobile Number',
                    maxLength: 10,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
            },
            firmEmailAddress: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'email'],
                labelConfig: {
                    label: 'Firm Email Address',
                    classes: ['floating-label'],
                    tooltip: MESSAGES.EMAIL_TOOLTIP
                },
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Firm Email Address',
                    maxLength: INPUT_MAX_LENGTH.LENGTH_EIGHTY,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
            },
            termsAndCondition:{
                isTermsAndCondition: true,
                value: false,
                valid: true,
                errorMessage:''
            }
        },
        error: '',
        isLoading: false
    };

    captchaElementReg = React.createRef();

    onCaptchaResolved = (captchaToken) => {
        (captchaToken && this.submitRegisterFormData(captchaToken));
    };

    onValidateCaptchaReg = () => {
        window.grecaptcha && window.grecaptcha.reset();
        this.captchaElementReg.execute(this.captchaElementResetId); // this.captchaElementResetId
    }


    inputChangedHandler = (inputValue, inputIdentifier) => {
        const {registrationForm} = this.state;
        const updatedForm = {...registrationForm};
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let value = inputValue;
        if(inputIdentifier === 'firmMobileNumber') {
            value = value.replace(/[^0-9]/g, '');
        }
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = '';
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({registrationForm: updatedForm, error: ''})
    };

    authenticateUser = (e) => {
        e.preventDefault();
        const { registrationForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(registrationForm);
        formKeys.forEach((key) => {
            validateObj[key] = registrationForm[key].value;
        });
        const formStatus = FormValidator.validateRegistrationForm(validateObj);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...registrationForm };
            formKeys.forEach((key) => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== '') {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
            });
            this.setState({ registrationForm: updatedForm, formErrorMessage: '' });
        } else {
            this.onValidateCaptchaReg();
        }
    };

    submitRegisterFormData = async (captchaToken) => {
        const { registrationForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(registrationForm);
        formKeys.forEach((key) => {
            validateObj[key] = registrationForm[key].value;
        });
        this.setState({isLoading: true});
        const payload = {
            mobileNumber: validateObj.firmMobileNumber,
            email: validateObj.firmEmailAddress,
            service: "REGISTER",
            name: validateObj.firmName,
            registrationFormPrivacy: validateObj.termsAndCondition,
            captchaToken,
        };
        try {
            const response = await commonService.generateOtp(payload);
            const responseData = interceptedData(response.data);
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                const { history } = this.props;
                history.push(PATHS.VERIFY_USER, {
                    firmName: validateObj.firmName,
                    mobileNumber: validateObj.firmMobileNumber,
                    email: validateObj.firmEmailAddress,
                });
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false})
        }
    };

    render() {
      const {openModal} = this.props;
        const {registrationForm, error, isLoading} = this.state;
        const {authenticateUser} = this;
        let formElementsArray = [];
        const formKeys = Object.keys(registrationForm);
        formKeys.forEach((key) => {
            formElementsArray.push({
                id: key,
                config: registrationForm[key],
            });
        });
        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <form onSubmit={authenticateUser}>
                    {
                        formElementsArray && formElementsArray.map(formElement => (
                            formElement.config.isTermsAndCondition ?
                                (<div key={`${formElement.id}`} className="notes align-left mtb20">
                                <div className={`checkbox-element ${!formElement.config.valid ? 'error':''}`}>
                                  <input type="checkbox" checked={formElement.config.value} onChange={(e)=>{this.inputChangedHandler(e.target.checked, formElement.id)}}/>
                                  <span className="checkmark" />
                                </div>
                                <div className="pl30 pr5">By submitting, I agree to all
                                    <span className="d-inline">
                                        <span
                                            className="title mrl5"
                                            onClick={()=>{
                                              openModal(MODALS.FILE_VIEWER_POPUP, {
                                                customModalContentClass: "file-viewer-modal",
                                                url: POLICY_URL.TERMS_OF_USE,
                                                urlType: "docx",
                                              });
                                            }}
                                        >
                                          Terms of Use
                                        </span>
                                        and
                                      <span
                                          className="title"
                                          onClick={()=>{
                                            openModal(MODALS.FILE_VIEWER_POPUP, {
                                              customModalContentClass: "file-viewer-modal",
                                              url: POLICY_URL.PRIVACY_POLICY,
                                              urlType: "docx",
                                            });
                                          }}
                                      >
                                        Privacy Policy
                                      </span>
                                    </span>
                                </div>
                                {!formElement.config.valid ? <span className="invalid-text">{formElement.config.errorMessage}</span>:null}
                              </div>)
                              :
                            (<Input
                                key={formElement.id}
                                labelConfig={formElement.config.labelConfig}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                valid={formElement.config.valid}
                                errorMessage={formElement.config.errorMessage}
                                touched={formElement.config.touched}
                                wrapperClasses={formElement.config.wrapperClasses}
                                inputGroupClasses={formElement.config.inputGroupClasses}
                                changed={event => this.inputChangedHandler(event, formElement.id)}
                            />)
                        ))
                    }

                    {
                        error && <div className="server-msg">{error}</div>
                    }
                    <ReCaptcha
                        apiKey={'6LcyQ-sUAAAAAGb5nyVY9CnZJiTYIARdrkWbe3EA'}
                        ref={this.captchaElementReg}
                        id="register-recaptcha"
                        customClass="customRecaptcha"
                        getRef={(ref) => {
                            this.captchaElementReg = ref;
                        }}
                        onChange={this.onCaptchaResolved}
                        resetId={(id) => {
                            this.captchaElementRegResetId = id;
                        }}
                    />

                    <button className="button fill full big mt10 mb20" type="submit">
                        REGISTER NOW
                    </button>
                </form>
              {/*<Modal />*/}
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
  openModal: bindActionCreators(openModalHandler, dispatch),
});

export default connect(null, mapDispatchToProps)(withRouter(Registration));
