import axios from "axios";
import CryptoJS from 'crypto-js';

export const serialize = (obj) => {
  const str = [];
  Object.keys(obj).forEach(p => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  /*for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }*/
  return str.join('&');
};

export const replaceStringWithParams = (str, obj) => {
  Object.keys(obj).forEach(j => {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  });
  /*for (const j in obj) {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  }*/
  return str;
};

export const isAPICanceled = (e) => {
  if(axios.isCancel(e)){
    return false;
  }
};

export const interceptedData = (data) => {
  // const data = 'xhjF+9h9ztGS1FQlp3kAoqRJhKeHx1RgFusuJ18MEBTcThB5rdX+qySdSbLhIrJbC91iZLytLmyclg4jTUQ25ukuMxGhvM7kgvZwzUzR2qRCAxoawYo8s8JQZ/ORZk8Ga7zX5gRYnkM52VBFWwmMCnR4GdXtqzarZcgdOn58F3ZxPuHC7fBKMgSOh94jNXBZMBWDSHlpLnVoJvDuqMNIxikhwt1o9BMh83ZYm+yKaP8jKgQzfCXFOausTWmVhSsZXegKVMhwNlqBv8xM90vKGGgAYyq0WYUt8p9Dz2KtdGdAhSQtFTDewEg+XtXeYGRSJ7+QuyE5DdROjv+tlWJBMH+XCUbetGroPGRfoExHGW87tqRvGviV2eIczep0QBdTLgLgbQQS3UaK0ndF7qMb4JdtaaB09uP5mdfRN85PWSESqB4Pmxrw++WTjj3zKNWdFdG+EumEE5bAxglGR4Y8yBUZ66bUFoEozcIhoooqQTvflTgtl4+g5xm26OXUPgCYnYSHkgwp28lQBR1pbVjVLRmWzog7Btm+unlBkPFyDXzLepEyDSs9iiOQrF+g5WocHsp4H6um74Qpl4SO67BHWt24JmlGlu0HiG+tRG22zWjxlO8Uls0Y+9qDcZQ+1dJFoCSfmqDE0eWZvAdP5UKD/MjY+SPH+06EfSspxFTye/ZlOAnYnSaMBkTSCXn/gwDgW4MsN7kEvTx7MQ0Hw2fUdZVxjMrQ7EAmz2nYci++5p7qTNgDsBvnTxzqFVRL1QBH50g3SgnC+bZj7g9z8cjGzBN7aSAF5GEo3nYmLwXYJIUoNAACxZeahCNj7Ow478mq1ICjDsCY+q5hM2Bwb5kAy/RFXzWUNWt6cfGUrqxEbAWP/AoxOcgcZyuDXUxnuIB2Pe2dsWdEDH51HhCFgDZ+Q0Ool8c+YWc4zMnH+5lgcNFUJvOG9pcMlg8d8YlImxgxBpEKsmJi8TNixqidoXxrzoydFSOADaOOjQOx6pMjxJbj4T/2IQUV4ScdnUP/dUFW/KMxBBaSvodzEfTeNSdguRTtXAvienTzNzQQl0h/F2NMeRwA/Et5ic0zdCDFA4JR'
  const responseKey = 'VkxoNHNldVZlRUtMTU5PUA=='; 
  const encryptString = data;
  const key = responseKey;
  const keyForCryptoJS = CryptoJS.enc.Base64.parse(key);
  const decodeBase64 = CryptoJS.enc.Base64.parse(encryptString);
  const decryptedData = CryptoJS.AES.decrypt(
    { ciphertext: decodeBase64 },
    keyForCryptoJS,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
  const parsedUserData = JSON.parse(decryptedText);
  return parsedUserData
};