import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import merge from 'lodash.merge';
import _get from 'lodash/get';
import LeftSidebar from "../Common/LeftSidebar";
import {MODALS} from "../../storeManager/modal/constants";
import {bindActionCreators} from "redux";
import {closeModal, openModal as openModalHandler} from "../../storeManager/modal/actions";

import IntentForm from "./IntentForm";
import EvaluationForm from "./EvaluationForm";
import DocumentSoftCopy from "./DocumentSoftCopy";
import DocumentHardCopy from "./DocumentHardCopy";
import MobileViewLeftSidebar from "./MobileViewLeftSidebar";
import GlobalConfig from "../../globalConfig/globalConfig";
import {commonService} from "../../storeManager/common/services";
import {AxiosService} from "../../services/apiService";
import FormValidator from "../../services/FormValidator";
import Loader from "../Loader";
// import Modal from "../Common/Modal";
import {RESPONSIVE, PATHS,MESSAGES, INPUT_MAX_LENGTH, ROLES_CATEGORY} from "../../constants";
import StatusDetails from './StatusDetails';
import { interceptedData } from '../../services/commonUtils';

import {numberFormatter} from '../../helper';

import {
    currentBusinessAssociationEvalForm,
    DefaultBankEvalFormObj,
    DefaultDocHardCopyState,
    DefaultDocumentSoftCopyForm,
    DefaultEvaluationFormState,
    DefaultIntentFormState,
    getNewBankObject,
    getNewPartnerObject,
    getUploadDocumentSelectedRadioVals,
    requestMapperForDocHardCopy,
    requestMapperForDocumentSoftCopy,
    requestMapperForEvaluation,
    requestMapperForIntent,
    responseMapperForDocHardCopy,
    responseMapperForDocumentSoftCopy,
    responseMapperForEvaluation,
    formKeys,
    responseMapperForIntent,
    DefaultPincodesFormObj,
    setIntentFormForSecondApplication,
    setEvaluationFormForSecondApplication,
    setSoftCopyFormForSecondApplication,
} from "./helper";
import {
    getBankList,
    getBusinessAssociationList,
    getExistingRelationshipList,
    getIntentForm,
    getStateList,
    uploadFile,
    getStoreTemplates
} from "../../storeManager/common/repository";
import "./application-form.scss";

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
});

class ApplicationForm extends Component {
    state = {
        isDocumentFormShow: false,
        partnerDocuments: {},
        uploadedDocumentsRadioValueKeys: [],
        serverPartnerObj: {},
        isLoading: true,
        isLoadingMessage: '',
        activeTab: 0,
        formErrorMessage: "",
        intentForm: DefaultIntentFormState(),
        evaluationForm: DefaultEvaluationFormState(),
        documentsSoftCopyForm: DefaultDocumentSoftCopyForm(),
        docHardCopyForm: DefaultDocHardCopyState(),
        intentFormIds: {
            firmId: "",
            businessDetailsId: "",
            personId: "",
            addressId: ""
        },
        evalFormIds: {
            businessDetailsId: "",
            appliedPartnershipId: ""
        },
        docHardCopyFormIds: {
            sendToType: ''
        },
        formConfig: {
            intentForm: {
                enable: true,
                editable: true,
                buttonArray: []
            },
            evaluationForm: {
                enable: true,
                editable: true,
                buttonArray: []
            },
            documentForm: {
                enable: true,
                editable: true,
                buttonArray: []
            },
            originalDocument: {
                enable: true,
                editable: true,
                ButtonArray: [],
                sendTo: {},
            },
        },
        partnerId: "",
        progressStatus:'',
        applicationAssignedTo:'',
        applicationId:'',
        serverBankList:[],
        formTownList: {
            intentForm: [],
            documentsSoftCopyForm: [],
        },
        storeTemplates:"",
        isSecondApplication: false,
        calculatedPincodesValue: ''
    };

    componentDidMount() {
        this.getInitialData();
    }

    getInitialData = async () => {
        // const {intentForm, evaluationForm, documentsSoftCopyForm} = this.state;

        // const updatedIntentForm = {...intentForm};
        // updatedIntentForm.termsAndCondition.value = false;

        // const updatedEvaluationForm  = {...evaluationForm};
        // updatedEvaluationForm.termsAndCondition.value = false;

        // const updatedDocumentsSoftCopyForm = {...documentsSoftCopyForm};
        // updatedDocumentsSoftCopyForm.termsAndCondition.value = false;

        this.setState({isLoading: true,
            // intentForm: updatedIntentForm,
            // evaluationForm: updatedEvaluationForm,
            // documentsSoftCopyForm: updatedDocumentsSoftCopyForm,
        });

        const { match:{ params: { id }}} = this.props
        const stateApi = new AxiosService(getStateList);
        const bankApi = new AxiosService(getBankList);
        const getBusinessAssociationApi = new AxiosService(
            getBusinessAssociationList
        );
        const getExistingRelationshipApi = new AxiosService(
            getExistingRelationshipList
        );

        const intentApi =  {...getIntentForm};
        const isRoleCategory = localStorage.getItem("roleCategory");
        const roleCategory = isRoleCategory && JSON.parse(isRoleCategory);
        if(id) {
            intentApi.url = `${intentApi.url}/${id}`;
        } else if(roleCategory !== ROLES_CATEGORY.PARTNER){
        //redirect to dashboard if not partner
            const { history } = this.props;
            history.push(PATHS.HOME);
            return;
        }
        const lineOfBusinessData = await commonService.fetchBusinessControllerData();
        let lobList = lineOfBusinessData.data.data;
        const lobStatus = lineOfBusinessData.data.status;
        let isLobSelected = false;
        const selectedLOB = JSON.parse(localStorage.getItem("dataFromDashboard"));
        if(selectedLOB && selectedLOB.lob) {
            intentApi.url = `${intentApi.url}/lob/${selectedLOB.lob}`;
            lobList = lobList.filter(lob => lob.name === selectedLOB.lob);
            isLobSelected = true;
        }
        const getIntentApi = new AxiosService(intentApi);
        const getAllStoreTemplates = new AxiosService(getStoreTemplates);

        Promise.all([
            stateApi.doAjax(),
            getBusinessAssociationApi.doAjax(),
            getExistingRelationshipApi.doAjax(),
            getIntentApi.doAjax(),
            bankApi.doAjax(),
        ])
            .then( async (values) => {
                let interceptedFormData = null;
                if (selectedLOB && selectedLOB.lob)
                    interceptedFormData = values[3].data
                else
                    interceptedFormData = interceptedData(values[3].data)
                values[3].data = interceptedFormData
                values[3].status = interceptedFormData.status ? 200 : null
                const [
                    {
                        data: { data: stateList, status: status1 }
                    },
                    {
                        data: { data: buAssocList, status: status2 }
                    },
                    {
                        data: { data: existRelList, status: status3 }
                    },
                    {
                        data: { data: formData, status: status4 }
                    },
                    {
                        data: { data: bankList, status: status5 }
                    }
                ] = values;
                let storeTemplates;
                    if(formData.evaluationForm.storeEnable) {
                        let response = await commonService.getAllStoreTemplates();
                        storeTemplates = response.data.data;
                    }
                if(status1 && status2 && status3 && status4 && lobStatus) {
                    this.setState({
                        intentForm: DefaultIntentFormState(),
                        evaluationForm: DefaultEvaluationFormState(),
                        documentsSoftCopyForm: DefaultDocumentSoftCopyForm(),
                        docHardCopyForm: DefaultDocHardCopyState(),
                        serverBankList: bankList && bankList.length && bankList || []
                    },()=>{
                        this.setInitialSelectLists(
                            stateList,
                            buAssocList,
                            existRelList,
                            bankList,
                            formData,
                            lobList,
                            isLobSelected,
                            storeTemplates
                        );
                    })
                } else {
                    this.setState({ isLoading: false });
                    if(id) {
                        //redirect to dashboard as not authorized to view
                        const { history } = this.props;
                        history.push(PATHS.HOME);
                        return;
                    }
                }
            })
            .catch(e => {
                this.setState({ isLoading: false });
                console.log("Error occured", e);
            });
    };

    closeModalGetData = (buttonType) => {
        const {activeTab} = this.state;
        const {history} = this.props;
        if(activeTab === 1 && !buttonType.validate) {
            window.location.reload();
        }else if(activeTab === 1){
            history.push(PATHS.HOME)
        }else{
            window.location.reload();
            // this.getInitialData();
        }

    }

    updateStoreTemplatesValue = (updatedEvaluationForm, formattedStoreTemplates) => {
        for(let formKey in updatedEvaluationForm) {
            formattedStoreTemplates.forEach(template => {
                if(formKey === template.identifier) {
                    updatedEvaluationForm[formKey].elementConfig.options = template.optionsValue;
                }
            });
        }
        return updatedEvaluationForm;
    }

    setInitialSelectLists = (stateList, buAssocList, existRelList, bankList, formData, lobList, isLobSelected, storeTemplates) => {
        const existRelListForEvaluation = existRelList.filter(rel => rel.lineOfBusiness.id === formData.partner.lineOfBusiness.id);
        let defaultOptions = [{ label: "Select", value: "" }];
        let formattedStateList = [
            ...defaultOptions,
            ...this.formatSelectValues(stateList)
        ];
        let formattedBuAssocList = [
            ...defaultOptions,
            ...this.formatSelectValues(buAssocList)
        ];
        let formattedExistRelList = [
            ...defaultOptions,
            ...this.formatSelectValues(existRelList)
        ];

        let formattedExistRelListForEvaluation = [
            ...defaultOptions,
            ...this.formatSelectValues(existRelListForEvaluation)
        ];

        let formattedBankList = [
            ...defaultOptions,
            ...this.formatSelectValues(bankList)
        ];
        let formattedLOBList = [
            ...this.formatSelectValues(lobList)
        ];
        const { intentForm, evaluationForm, documentsSoftCopyForm } = this.state;
        let updatedIntentForm = { ...intentForm };
        // updatedIntentForm.state.elementConfig.options = formattedStateList;
        updatedIntentForm.currentBusinessSelectInputConfig[0].selectConfig.elementConfig.options = formattedBuAssocList;
        updatedIntentForm.selectInputConfig[0].selectConfig.elementConfig.options = formattedExistRelList;
        updatedIntentForm.lineOfBusiness.elementConfig.options = formattedLOBList;
        updatedIntentForm.lineOfBusiness.elementConfig.disabled = isLobSelected || formData.defaultForm === 'EVALUATION';

        let updatedEvaluationForm = { ...evaluationForm };
        updatedEvaluationForm.partnerType.elementConfig.options = formattedExistRelListForEvaluation;
        updatedEvaluationForm.currentBusinessAssociation[0].curAssoc.selectConfig.elementConfig.options = formattedBuAssocList;
        updatedEvaluationForm.bankDetails[0].bank.elementConfig.options = formattedBankList;

        let formattedStoreTemplates;
        if(formData.evaluationForm.storeEnable) {
            formattedStoreTemplates = [
                ...defaultOptions,
                ...this.formatStoreTemplateValues(storeTemplates, defaultOptions)
            ]
            updatedEvaluationForm = this.updateStoreTemplatesValue(updatedEvaluationForm, formattedStoreTemplates);
            this.setState({
                storeTemplates: storeTemplates
            })
        }

        let updatedDocumentsSoftCopyForm = { ...documentsSoftCopyForm };
        updatedDocumentsSoftCopyForm.state.elementConfig.options = formattedStateList;
        updatedDocumentsSoftCopyForm.bankDetails[0][
            "bankName"
        ].elementConfig.options = formattedBankList;
        if(formData.isApplicationAlreadyExist) {
            updatedIntentForm = setIntentFormForSecondApplication(updatedIntentForm);
            updatedEvaluationForm = setEvaluationFormForSecondApplication(updatedEvaluationForm);
        }
        if(formData.isApplicationAlreadyExist) {
            updatedDocumentsSoftCopyForm = setSoftCopyFormForSecondApplication(updatedDocumentsSoftCopyForm, formData.documentForm.banks[0]);
        }
        this.setState(
            {
                isLoading: false,
                serverPartnerObj: { ...formData.partner },
                intentForm: { ...updatedIntentForm },
                evaluationForm: { ...updatedEvaluationForm },
                documentsSoftCopyForm: { ...updatedDocumentsSoftCopyForm },
                activeTab: formKeys[formData.defaultForm].activeTab || 0,
                isSecondApplication: formData.isApplicationAlreadyExist
            },
            () => {
                this.setIntentFormData(formData);
                this.setEvaluationFormData(formData, formattedStoreTemplates);
                this.setInitialDocumentSoftCopyFormData(formData);
                this.setHardCopyFormData(formData);
                this.getFormConfig(formData);
            }
        );
    };

    setInitialDocumentSoftCopyFormData = formData => {
        const formType = "documentsSoftCopyForm";
        if (!formData) return;
        this.setState(
            {
                documentsSoftCopyForm: responseMapperForDocumentSoftCopy(
                    formData.documentForm,
                    this.state.documentsSoftCopyForm,
                    formData.partner,
                ),
                partnerDocuments: (formData.documentForm && formData.documentForm.partnerDocument) || {},
                documentSoftCopyIds: {
                    wareHouseId:
                        (formData.documentForm &&
                            formData.documentForm.wareHouse &&
                            formData.documentForm.wareHouse.id) ||
                        "",
                    wareHouseAddressId:
                        (formData.documentForm &&
                            formData.documentForm.wareHouse &&
                            formData.documentForm.wareHouse.address &&
                            formData.documentForm.wareHouse.address.id) ||
                        "",
                    partnerDocumentId: (formData.documentForm && formData.documentForm.partnerDocument && formData.documentForm.partnerDocument.id) || ""
                },
                uploadedDocumentsRadioValueKeys: getUploadDocumentSelectedRadioVals(formData.documentForm) || []
            },
            () => {
                if (this.state.documentsSoftCopyForm.pinCode.value) {
                    this.getTownByPincode(formType, true);
                }
                // if (this.state.documentsSoftCopyForm.state.value) {
                //     this.getCityListForSelectedState(formType);
                // }
                // if (this.state.documentsSoftCopyForm.city.value) {
                //     this.getPincodeByCity(formType);
                // }
                this.setDocumentSoftCopyInitialArrayFields(formData.documentForm, formType);
            }
        );
    };

    setDocumentSoftCopyInitialArrayFields = (formData, formType) => {
        let partnerDetails = formData.partnerDetails;
        const selectedForm = this.state[formType];
        let updatedForm = { ...selectedForm };
        let bankDetails = formData.banks;
        this.setDocumentSoftCopyFormPartnerDetails(partnerDetails, updatedForm, formType);
        this.setDocumentSoftCopyFormBankDetails(bankDetails, updatedForm, formType);
    };

    setDocumentSoftCopyFormPartnerDetails = (partnerDetails, updatedForm, formType) => {
        const { serverPartnerObj: {firm: { firmType, warehouseAvailable }}} = this.state;
        if(firmType !== 'PARTNER') {
            updatedForm.partnerDetailsHeading.isHide = true;
            updatedForm['partnerDetails'].length = 0;
        }
        else {
            if (partnerDetails && partnerDetails.length) {
                partnerDetails.forEach((partner, index) => {
                    if (index === 0) {
                        let updatedPartnerObj = updatedForm['partnerDetails'][0];
                        updatedPartnerObj.id = partner.id;
                        this.documentsArrayFiledChangeHandler(
                            partner.name,
                            "partnerDetails",
                            formType,
                            0,
                            "partnerName"
                        );
                        this.documentsArrayFiledChangeHandler(
                            partner.pan,
                            "partnerDetails",
                            formType,
                            0,
                            "partnerPanNumber"
                        );
                    } else {
                        let newObj = getNewPartnerObject(partner);
                        this.addRemoveObj(
                            formType,
                            "partnerDetails",
                            "More",
                            index,
                            "",
                            "",
                            "",
                            newObj
                        );
                    }
                })
            }
        }
         if(!warehouseAvailable){
            updatedForm.warehouseDetails.isHide = true;
            updatedForm.totalArea.isHide = true;
            updatedForm.managerName.isHide = true;
            updatedForm.managerMobileNo.isHide = true;
            updatedForm.address.isHide = true;
            updatedForm.state.isHide = true;
            updatedForm.city.isHide = true;
            updatedForm.town.isHide = true;
            updatedForm.pinCode.isHide = true;
        }
    };

    setDocumentSoftCopyFormBankDetails=(bankDetails,updatedForm,formType)=>{
        if (bankDetails && bankDetails.length) {
            bankDetails.forEach((bank, index) => {
                let updatedBankObj = updatedForm['bankDetails'][0];
                let bankNameOptions = [];
                if (updatedBankObj["bankName"]) {
                    bankNameOptions = updatedBankObj["bankName"].elementConfig.options;
                }
                if (index === 0) {
                    updatedBankObj.id = bank.id;
                    this.documentsArrayFiledChangeHandler(
                        bank.bank.id,
                        "bankDetails",
                        formType,
                        0,
                        "bankName"
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.name,
                        "bankDetails",
                        formType,
                        0,
                        "otherBank"
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.accountNo,
                        "bankDetails",
                        formType,
                        0,
                        "bankAccountNumber"
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.ccLimit,
                        "bankDetails",
                        formType,
                        0,
                        "ccLimit"
                    );
                } else {
                    let newObj = getNewBankObject(bank, bankNameOptions);
                    this.addRemoveObj(
                        formType,
                        "bankDetails",
                        "More",
                        index,
                        "",
                        "",
                        "",
                        newObj
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.bank.id,
                        "bankDetails",
                        formType,
                        index,
                        "bankName"
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.name,
                        "bankDetails",
                        formType,
                        index,
                        "otherBank"
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.accountNo,
                        "bankDetails",
                        formType,
                        index,
                        "bankAccountNumber"
                    );
                    this.documentsArrayFiledChangeHandler(
                        bank.ccLimit,
                        "bankDetails",
                        formType,
                        index,
                        "ccLimit"
                    );

                }
            })
        }
    };

    setIntentFormData = formData => {
        const formType = "intentForm";
        if (!formData) return;
        this.setState(
            {
                isLoading: false,
                intentForm: responseMapperForIntent(
                    formData.intentForm,
                    this.state.intentForm,
                ),
                partnerId: formData.partnerId || "",
                progressStatus: formData.progressStatus || '',
                applicationAssignedTo: formData.assignedTo || '',
                applicationId: formData.applicationId || '',
                intentFormIds: {
                    personId: (formData.intentForm && formData.intentForm.personId) || "",
                    firmId: (formData.intentForm && formData.intentForm.firmId) || "",
                    businessDetailsId:
                        (formData.intentForm && formData.intentForm.businessDetailsId) ||
                        "",
                    addressId:
                        (formData.intentForm &&
                            formData.intentForm.address &&
                            formData.intentForm.address.id) ||
                        ""
                }
            },
            () => {
                if (this.state.intentForm.pinCode.value) {
                    this.getTownByPincode(formType, true);
                }
                /*if (this.state.intentForm.state.value) {
                    this.getCityListForSelectedState(formType);
                }
                if (this.state.intentForm.city.value) {
                    this.getPincodeByCity(formType)
                }*/
                const {
                    intentForm: { currentBusinessAssociations, existingRelationship }
                } = formData;

                if (currentBusinessAssociations && currentBusinessAssociations.length) {
                    this.addInitialObjIntentForm(
                        formType,
                        currentBusinessAssociations,
                        "currentBusinessSelectInputConfig",
                        "digitalService",
                        "company"
                    );
                }

                if (existingRelationship && existingRelationship.length) {
                    this.addInitialObjIntentForm(
                        formType,
                        existingRelationship,
                        "selectInputConfig",
                        "channel",
                        "partnerCode",
                        "code"
                    );
                }
            }
        );
    };

    setEvaluationFormData = (formData, formattedStoreTemplates) => {
        const formType = "evaluationForm"
        if (!formData) return;
        this.setState(
            {
                isLoading: false,
                evaluationForm: responseMapperForEvaluation(
                    formData.evaluationForm,
                    this.state.evaluationForm,
                    formData.intentForm,
                    formattedStoreTemplates
                ),
                evalFormIds: {
                    businessDetailsId:
                        (formData.evaluationForm &&
                            formData.evaluationForm.businessDetail &&
                            formData.evaluationForm.businessDetail.id) ||
                        "",
                    appliedPartnershipId: (formData.evaluationForm &&
                        formData.evaluationForm.appliedPartnership &&
                        formData.evaluationForm.appliedPartnership.id) ||
                        "",
                }
            },
            () => {
                if (this.state.evaluationForm.storeEnable && this.state.evaluationForm.pinCode.value) {
                    this.getTownByPincode(formType, true);
                }
                const {
                    evaluationForm: { businessDetail, banks }
                } = formData;

                if (
                    businessDetail &&
                    businessDetail.businessAssociations &&
                    businessDetail.businessAssociations.length
                ) {
                    this.addInitialObjEvalForm(businessDetail.businessAssociations);
                }

                if (banks && banks.length) {
                    this.addInitialBankEvalForm(banks)
                }
            }
        );
    };

    setHardCopyFormData = formData => {
        if (!formData) return;
        this.setState(
            {
                isDocumentFormShow: true,
                docHardCopyForm: responseMapperForDocHardCopy(
                    formData.originalDocument,
                    this.state.docHardCopyForm
                ),
                docHardCopyFormIds: {
                    sendToType:
                        (formData.originalDocument &&
                            formData.originalDocument.sendToType) ||
                        "",
                }
            }
        );

    }

    addRemoveObj = (
        formType,
        objKey,
        btnType,
        index,
        inputValue = "",
        selectValue = "",
        serverId = "",
        objToBeAdded,
    ) => {
        let selectedForm = this.state[formType];
        const updateForm = { ...selectedForm };
        let obj = {};
        if (formType === "documentsSoftCopyForm") {
            obj = objToBeAdded;
        } else {
            const { [objKey]: dynKey } = updateForm;

            const {
                selectConfig: {
                    elementConfig: { options }
                }
            } = dynKey[0];
            let maxLength = formType === 'intentForm' && objKey === 'selectInputConfig' ? INPUT_MAX_LENGTH.LENGTH_FIVE : INPUT_MAX_LENGTH.LENGTH_TWENTY_FOUR;
            let placeholderText = formType === 'intentForm' && objKey === 'selectInputConfig' ? "Enter Applicant Code" : "Enter Brand Name";
            obj = {
                wrapperClasses: ["form-element select-input-form"],
                inputGroupClasses: ["select-input show-placeholder"],
                elementType: "text",
                elementConfig: {
                    type: "text",
                    placeholder: placeholderText,
                    maxLength: maxLength,
                    tabIndex: 0
                },
                value: inputValue,
                id: serverId || null,
                errorMessage: "",
                valid: true,
                touched: false,
                selectConfig: {
                    elementConfig: {
                        type: "select",
                        value: selectValue,
                        placeholder: "Select",
                        tabIndex: 0,
                        options: [...options]
                    }
                },
                buttonConfig: {
                    type: "Remove",
                    text: "-",
                    buttonClass: "add-btn remove"
                }
            };
        }
        if (btnType === "More") {
            updateForm[objKey].push(obj);
        } else {
            updateForm[objKey].splice(index, 1);
        }
        this.setState({ [formType]: updateForm });
    };

    addInitialObjEvalForm = inputArray => {
        if (inputArray && inputArray.length) {
            inputArray.forEach((item, index) => {
                this.toSetEvalReplicatedData(item, index);
            });
        }
    };

    addInitialBankEvalForm = inputArray => {
        if (inputArray && inputArray.length) {
            inputArray.forEach((item, index) => {
                this.toSetBankEvalReplicatedData(item, index);
            });
        }
    };

    addInitialObjIntentForm = (
        formType,
        inputArray,
        inputIdentifier,
        selectKey,
        inputKey,
        inputNestedKey
    ) => {
        if (inputArray && inputArray.length) {
            inputArray.forEach((item, index) => {
                let inputValue =
                    inputIdentifier === "selectInputConfig"
                        ? item[inputKey] && item[inputKey][inputNestedKey]
                        : item[inputKey];
                let selectValue = (item[selectKey] && item[selectKey].id) || "";

                if (index === 0) {
                    this.selectHandler(selectValue, inputIdentifier, 0, formType);
                    this.selectInputConfigHandler(
                        formType,
                        inputValue,
                        inputIdentifier,
                        index,
                        item.id
                    );
                } else {
                    this.addRemoveObj(
                        formType,
                        inputIdentifier,
                        "More",
                        index,
                        inputValue,
                        selectValue,
                        item.id,
                    );
                }
            });
        }
    };

    formatSelectValues = selectList => {
        let formattedList =
            (selectList &&
                selectList.length &&
                selectList.map(item => {
                    return {
                        label: item.name,
                        value: item.id
                    };
                })) ||
            [];
        return formattedList;
    };

    formatStoreTemplateValues = (storeTemplates, defaultOptions) => {
            let itemOptions = [];
            if(!storeTemplates || storeTemplates==='undefined') {
                return;
            }
            storeTemplates.forEach(templateKey => {
                templateKey.templateTypeKeys.forEach(template => {
                    let formattedList = [];
                    template.optionKeys.forEach(key => {
                        
                        formattedList.push({
                            label: key.keyName,
                            value: key.id,
                            points: key.points,
                            disabledFields: key.disabledFields
                        });
                    })
                    itemOptions.push({
                        identifier: templateKey.customTemplateIdentifier,
                        optionsValue: [...defaultOptions, ...formattedList]
                    })
                });
            })
        return itemOptions;
    };

    formatSelectValuesForPincode = dataList => {
        let formattedList =
            (dataList &&
                dataList.length &&
                dataList.map(item => {
                    return {
                        label: item.code,
                        value: item.id
                    };
                })) ||
            [];
        return formattedList;
    };

    getCityListForSelectedState = async formType => {
        let selectedForm = this.state[formType];
        let updatedForm = { ...selectedForm };
        let defaultOptions = [{ label: "Select", value: "" }];
        if (updatedForm.state.value) {
            this.setState({ isLoading: true });
        } else {
            updatedForm.city.value = "";
            updatedForm.pinCode.value = "";
            updatedForm.city.elementConfig.options = [...defaultOptions];
            this.setState({
                [formType]: { ...updatedForm }
            });
            return null;
        }

        const payload = { id: updatedForm.state.value };
        try {
            const response = await commonService.getCityList(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                let cityList = this.formatSelectValues(responseData.data);
                updatedForm.city.elementConfig.options = [
                    ...defaultOptions,
                    ...cityList
                ];
                this.setState({
                    isLoading: false,
                    [formType]: { ...updatedForm }
                });
            } else {
                this.setState({ error: responseData.message, isLoading: false });
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isLoading: false });
        }
    };

    getPincodeByCity = async formType => {
        let selectedForm = this.state[formType];
        let updatedForm = { ...selectedForm };
        let defaultOptions = [{ label: "Select", value: "" }];
        if (updatedForm.city.value) {
            this.setState({ isLoading: true });
        } else {
            updatedForm.pinCode.value = "";
            updatedForm.pinCode.elementConfig.options = [...defaultOptions];
            this.setState({
                [formType]: { ...updatedForm }
            });
            return null;
        }

        const payload = { cityId: +updatedForm.city.value };
        try {
            const response = await commonService.getPincodeList(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                let pinCodeList = this.formatSelectValuesForPincode(responseData.data);
                updatedForm.pinCode.elementConfig.options = [
                    ...defaultOptions,
                    ...pinCodeList
                ];
                this.setState({
                    isLoading: false,
                    [formType]: { ...updatedForm }
                });
            } else {
                this.setState({ error: responseData.message, isLoading: false });
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isLoading: false });
        }
    };

    getTownByPincode = async (formType, isInitialLoad) => {
        try {
            let selectedForm = this.state[formType];
            let updatedForm = { ...selectedForm };
            let defaultOptions = [{ label: "Select", value: "" }];
            if(isInitialLoad && updatedForm.town.value) {
                // do nothing
            } else {
                updatedForm.town.value = "";
                updatedForm.town.elementConfig.options = [...defaultOptions];
                updatedForm.city.value = "";
                updatedForm.city.elementConfig.options = [...defaultOptions];
                updatedForm.state.value = "";
                updatedForm.state.elementConfig.options = [];
            }

            this.setState({
                [formType]: { ...updatedForm }
            });

            const payload = { pinCode: updatedForm.pinCode.value };
            try {
                const response = await commonService.getTownsList(payload);
                const responseData = response.data;
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    if (!responseData.data) {
                        updatedForm.pinCode.errorMessage = responseData.message;
                        updatedForm.pinCode.valid = responseData.message ? false : true;
                    }
                    let townList = this.formatSelectValues(responseData.data);
                    updatedForm.town.elementConfig.options = [
                        ...defaultOptions,
                        ...townList
                    ];

                    this.setState({
                        isLoading: false,
                        [formType]: { ...updatedForm },
                        formTownList: { ...this.state.formTownList, [formType]: responseData.data},
                    }, () => {
                        if(updatedForm.town.value) {
                            this.getStateByTown(formType);
                        }
                    });
                } else {
                    this.setState({ error: responseData.message, isLoading: false });
                }
            } catch {
                this.setState({ error: GlobalConfig.serverError, isLoading: false });
            }
        } catch (e) {
            console.log('error',e)
        }
    };

    getStateByTown = async formType => {
        const { formTownList } = this.state;
        try {
            let selectedForm = this.state[formType];
            let updatedForm = { ...selectedForm };
            const selectedTownId = updatedForm.town.value;
            const townData = formTownList[formType].find(element => element.id === parseInt(selectedTownId, 10));
            let defaultOptions = [];
            updatedForm.city.value = _get(townData, 'pinCode.city.id','').toString();
            updatedForm.city.elementConfig.options = [{ label: _get(townData, 'pinCode.city.name',''), value: _get(townData, 'pinCode.city.id','') }];
            updatedForm.state.value = _get(townData, 'state.id','').toString();
            updatedForm.state.elementConfig.options = [{ label: _get(townData, 'state.name',''), value: _get(townData, 'state.id','') }];
            updatedForm.state.valid = true;
            updatedForm.state.errorMessage = "";

            this.setState({
                [formType]: { ...updatedForm }
            });
        } catch (e) {
            console.log('error',e)
        }
    };

    selectHandler = (selected, inputIdentifier, index, formType) => {
        let selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        const updatedFormElement = [...updatedForm[inputIdentifier]];
        if (index !== null) {
            updatedFormElement[index].selectConfig.elementConfig.value = selected;

            updatedFormElement[index].touched = true;
            updatedFormElement[index].valid = true;
            updatedFormElement[index].errorMessage = "";
        } else {
            updatedFormElement.selectConfig.elementConfig.value = selected;
        }
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({ [formType]: updatedForm });
    };

    storeSelectHandler = (selected, inputIdentifier, index, formType) => {
        let selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        const updatedFormElement = [...updatedForm[inputIdentifier]];
        if (index !== null) {
            updatedFormElement[index].selectConfig.elementConfig.value = selected;

            updatedFormElement[index].touched = true;
            updatedFormElement[index].valid = true;
            updatedFormElement[index].errorMessage = "";
        } else {
            updatedFormElement.selectConfig.elementConfig.value = selected;
        }
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({ [formType]: updatedForm });
    };

    selectInputConfigHandler = (
        formType,
        inputValue,
        inputIdentifier,
        index,
        serverId = ""
    ) => {
        let value = inputValue;
        if(value && value.charAt(0) === ' ') {
            value = value.substr(1);
        }
        if (
            formType === "intentForm" &&
            inputIdentifier === "selectInputConfig"
        ) {
            value = value.replace(/[^0-9]/g, "");
        }
        let selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        const updatedFormElement = [...updatedForm[inputIdentifier]];
        updatedFormElement[index].value = value;
        updatedFormElement[index].id = serverId || null;
        updatedFormElement[index].touched = true;
        updatedFormElement[index].valid = true;
        updatedFormElement[index].errorMessage = "";
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({ [formType]: updatedForm });
    };

    fileHandler = () => {
        return {
            onChangeImageHandler: (e, formElementId, formType) => {
                let selectedForm = this.state[formType];
                this.loading(true, MESSAGES.UPLOAD_FILE);
                const updatedForm = { ...selectedForm };
                const file = e.target.files[0];
                const formData = new FormData();
                formData.append(`file`, file);
                uploadFile.params = formData;
                const api = new AxiosService(uploadFile);
                Promise.all([api.doAjax()]).then( values => {
                  let response = values[0];
                  if(response && response.data && response.data.data) {
                    const { data } =response.data;
                    let docObj = {
                      id: data.id,
                      name: data.name,
                      url: data.url,
                    };
                    updatedForm[formElementId].valid = true;
                    updatedForm[formElementId].errorMessage = "";
                    updatedForm[formElementId].docList[0] = docObj;
                    this.loading(false, '');
                    this.setState({ [formType]: updatedForm });
                  } else {
                      // todo handle file upload error
                    updatedForm[formElementId].valid = false;
                    updatedForm[formElementId].errorMessage = "Error in uploading";
                    // updatedForm[formElementId].docList[0] = docObj;
                    this.setState({ [formType]: updatedForm });
                    this.loading(false, '');
                  }
                }).catch(err => {
                    this.loading(false, '');
                    console.log(err)
                })
            },
            deleteUploadedDocument: (e, formType, ) => {
                let selectedForm = this.state[formType];
                const updatedForm = { ...selectedForm };
                updatedForm.hardCopyUpload.docList = [];
                this.setState({ [formType]: updatedForm });
            },
        }
    };

    radioChangeHandler = (inputValue, inputIdentifier, index, formType) => {
        let selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        let updatedFormElement;
            updatedFormElement = { ...updatedForm[inputIdentifier] };
            updatedFormElement.radioConfig.forEach(value => {
                value.elementConfig.checked = false;
            });
            if((formType === 'intentForm' && inputIdentifier==='gst') || (formType === 'evaluationForm' && inputIdentifier==='isFamilyInDTH')){
                updatedFormElement.touched = true;
                updatedFormElement.valid = true;
                updatedFormElement.errorMessage = "";
                if(index === 1) {
                    updatedFormElement.value = "";
                }
            }
            
            if(formType === 'intentForm' && inputIdentifier==='existingRelationship'){
                updatedForm.selectInputConfig && updatedForm.selectInputConfig.length &&
                updatedForm.selectInputConfig.forEach(item=>{
                    item.touched = true;
                    item.valid = true;
                    item.errorMessage = "";
                })
            }
            updatedFormElement.radioConfig[index].elementConfig.checked = true;
            updatedForm[inputIdentifier] = updatedFormElement;
            this.setState({ [formType]: updatedForm });
    };

    termsConditionChangeHandler = (checked, formType) => {
        const selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        updatedForm.termsAndCondition.value = checked;
        updatedForm.termsAndCondition.valid = true;
        updatedForm.termsAndCondition.errorMessage = "";
        this.setState({ [formType]: updatedForm });
    };

    inputChangedHandler = (inputValue, inputIdentifier, formType) => {
        const selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        // if(formType === "evaluationForm" && selectedForm.storeOnboard) {

        // }
        const updatedFormElement = { ...updatedForm[inputIdentifier] };
        if (updatedFormElement.isGradeAvailable) {
            const disabledFieldsOptions = updatedFormElement.elementConfig.options.filter(option => option.disabledFields != null);
            if(disabledFieldsOptions.length > 0) {
                disabledFieldsOptions.forEach(option => {
                    const fields = option.disabledFields.split(',');
                    fields.forEach(field => {
                        updatedForm[field].elementConfig.disabled = false;
                        updatedForm[field].errorMessage = "";
                        updatedForm[field].touched = true;
                        updatedForm[field].valid = true;
                    })
                })
            }
            const selectedOption = updatedFormElement.elementConfig.options.filter(option => option.value ==  inputValue && option.disabledFields != null);
            if(selectedOption.length > 0) {
                const disabledFields = selectedOption[0].disabledFields.split(',');
                if (disabledFields.length > 0) {
                    disabledFields.forEach(field => {
                        updatedForm[field].elementConfig.disabled = true;
                        updatedForm[field].value = "";
                    })
                }
            }
        }
        if(inputIdentifier === 'briefDescription') {
            if (inputValue == 4) {
                updatedForm['otherDescription'].elementConfig.disabled = false;
            } else {
                updatedForm['otherDescription'].elementConfig.disabled = true;
                updatedForm['otherDescription'].value = "";
            }
            updatedForm['otherDescription'].errorMessage = "";
            updatedForm['otherDescription'].touched = true;
            updatedForm['otherDescription'].valid = true;
        }
        let value = inputValue.toString();
        if(value && value.charAt(0) === ' ') {
            value = value.substr(1);
        }
        if (
            inputIdentifier === "pinCode" ||
            inputIdentifier === "currentManpower" ||
            inputIdentifier === "managerMobileNo" ||
            inputIdentifier === "totalArea" ||
            inputIdentifier === "shopArea" ||
            inputIdentifier === "manpowerCapacity" ||
            inputIdentifier === "evdMobileNo" ||
            inputIdentifier === "territoryCovered" ||
            inputIdentifier === "expectedTurnover" ||
            inputIdentifier === "businessTurnover"
        ) {
            value = value.replace(/[^0-9]/g, "");
            if(inputIdentifier === "expectedTurnover" || inputIdentifier === "businessTurnover"){
                value = numberFormatter(value);
            }
        }
        if (
            inputIdentifier === "managerName"
        ) {
            if (value.charAt(0) === ' ') {
                value = value.replace(/[^a-zA-Z]/g, "");
            } else value = value.replace(/[^a-zA-Z ]/g, "");
        }
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = "";
        if ((formType === "intentForm" && inputIdentifier === "state") ||
            (formType === "documentsSoftCopyForm" && inputIdentifier === "state")) {
            updatedForm.city.value = '';
            updatedForm.pinCode.value = ''
        }
        if ((formType === "intentForm" && inputIdentifier === "city") ||
            (formType === "documentsSoftCopyForm" && inputIdentifier === "city")) {
            updatedForm.pinCode.value = ''
        }
        updatedForm[inputIdentifier] = updatedFormElement;

        this.setState({ [formType]: updatedForm, formErrorMessage:'' }, () => {
            //to fetch city list on state change
            /*if (inputIdentifier === "state") {
                this.getCityListForSelectedState(formType);
            }
            if(inputIdentifier === 'city') {
                this.getPincodeByCity(formType)
            }*/
            if(inputIdentifier === 'pinCode' && value.length === 6) {
                this.getTownByPincode(formType)
            }
            if(inputIdentifier === 'town') {
                this.getStateByTown(formType)
            }
        });
    };

    evalFormBankChageHandler = (
        inputValue,
        inputIdentifier,
        index,
    ) => {
        let isSelect = inputIdentifier === 'bank';
        const { evaluationForm, serverBankList } = this.state;
        const updatedForm = { ...evaluationForm };
        let updatedFormElement = updatedForm.bankDetails[index];

        if (
            (updatedFormElement[inputIdentifier].elementType === "input" ||
                updatedFormElement[inputIdentifier].elementType === "textarea" ||
                updatedFormElement[inputIdentifier].elementType === "text") &&
            !isSelect
        ){
            updatedFormElement[inputIdentifier].value = inputValue;

        }else if ( isSelect) {
            let bankListObj = serverBankList && serverBankList.length &&
                serverBankList.find(item =>  item.id == inputValue);
            updatedFormElement[inputIdentifier].value = inputValue;
            if(bankListObj && bankListObj.others){
                updatedFormElement[inputIdentifier].isOther = true;
            }else{
                updatedFormElement[inputIdentifier].isOther = false;
                updatedFormElement.otherBank.value = '';
                updatedFormElement.otherBank.touched = true;
                updatedFormElement.otherBank.valid = true;
                updatedFormElement.otherBank.errorMessage = "";
            }

        }
        updatedFormElement[inputIdentifier].touched = true;
        updatedFormElement[inputIdentifier].valid = true;
        updatedFormElement[inputIdentifier].errorMessage = "";
        this.setState({ evaluationForm: updatedForm });
    };

    evalFormPincodesChageHandler = (
        inputValue,
        inputIdentifier,
        index,
    ) => {
        // let isSelect = inputIdentifier === 'pinCodes';
        const { evaluationForm, serverBankList } = this.state;
        const updatedForm = { ...evaluationForm };
        let updatedFormElement = updatedForm.pinCodes[index];

        if (updatedFormElement[inputIdentifier].elementType === "input"){
            updatedFormElement[inputIdentifier].value = inputValue;

        }
        updatedFormElement[inputIdentifier].touched = true;
        updatedFormElement[inputIdentifier].valid = true;
        updatedFormElement[inputIdentifier].errorMessage = "";
        updatedForm.activeSubscriber.value = "";
        this.setState({ evaluationForm: updatedForm });
    };

    evalFormCalculatePincodesHandler = async (e, inputIdentifier) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        let updatedFormElement = updatedForm[inputIdentifier];
        
        let params = {pincodes: []};
        let formType = 'evaluationForm';
        
        updatedFormElement.map(item => {
            params.pincodes.push(item.pinCodes.value);
        });

        const validateResponse = this.validateActiveSubscriberField(updatedForm, formType);

        if(!validateResponse) {
            let response = await commonService.activeSubscriberCalculation(params);
            let calculatedPincodesData = response.data;
            updatedForm.activeSubscriber.value = calculatedPincodesData;
            updatedForm.activeSubscriber.errorMessage = "";
            updatedForm.activeSubscriber.touched = true;
            updatedForm.activeSubscriber.valid = true;
            this.setState({ evaluationForm: updatedForm });
        }        
    };

    evalFormAssocFieldChageHandler = (
        inputValue,
        inputIdentifier,
        objKey,
        index,
        isSelect
    ) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        let updatedFormElement = updatedForm[objKey][index];
        let value = inputValue;
        if(value && value.charAt(0) === ' ') {
            value = value.substr(1);
        }
        if (
            (updatedFormElement[inputIdentifier].elementType === "input" ||
                updatedFormElement[inputIdentifier].elementType === "textarea" ||
                updatedFormElement[inputIdentifier].elementType === "text") &&
            !isSelect
        ) {
            if ((inputIdentifier === "since") || (inputIdentifier === "marketArea")) {
                value = value.replace(/[^0-9]/g, "");
            }

            updatedFormElement[inputIdentifier].value = value;
        } else if (updatedFormElement[inputIdentifier].selectConfig && isSelect) {
            updatedFormElement[
                inputIdentifier
            ].selectConfig.elementConfig.value = value;
        }
        updatedFormElement[inputIdentifier].touched = true;
        updatedFormElement[inputIdentifier].valid = true;
        updatedFormElement[inputIdentifier].errorMessage = "";

        updatedForm[objKey][index] = updatedFormElement;
        this.setState({ evaluationForm: updatedForm });
    };

    toSetEvalReplicatedData = (data, index) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        if (index === 0) {
            updatedForm.currentBusinessAssociation[0].id.value = (data && data.id) || '';
            updatedForm.currentBusinessAssociation[0].curAssoc.selectConfig.elementConfig.value =
                (data && data.digitalService && data.digitalService.id) || "";
            updatedForm.currentBusinessAssociation[0].curAssoc.value =
                (data && data.company) || "";
            updatedForm.currentBusinessAssociation[0].marketArea.value =
                (data && data.marketAreaCovered) || "";
            updatedForm.currentBusinessAssociation[0].since.value =
                (data && data.sinceYear) || "";
            updatedForm.currentBusinessAssociation[0].sisterConcern.value =
                (data && data.sisterConcern) || "";
            updatedForm.currentBusinessAssociation[0].remark.value =
                (data && data.remarks) || "";
        } else {
            let newObj = currentBusinessAssociationEvalForm();
            newObj.curAssoc.selectConfig.elementConfig.options =
                updatedForm.currentBusinessAssociation[0].curAssoc.selectConfig.elementConfig.options;
            newObj.id.value = (data && data.id) || '';
            newObj.curAssoc.selectConfig.elementConfig.value =
                (data && data.digitalService && data.digitalService.id) || "";
            newObj.curAssoc.value =
                (data && data.company) || "";
            newObj.marketArea.value =
                (data && data.marketAreaCovered) || "";
            newObj.since.value =
                (data && data.sinceYear) || "";
            newObj.sisterConcern.value =
                (data && data.sisterConcern) || "";
            newObj.remark.value =
                (data && data.remarks) || "";
            updatedForm.currentBusinessAssociation.push(newObj);
        }
        this.setState({ evaluationForm: updatedForm });
    };

    toSetBankEvalReplicatedData = (data, index) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        if (index === 0) {
            updatedForm.bankDetails[0].id = (data && data.id) || '';
            updatedForm.bankDetails[0].bank.value = (data && data.bank && data.bank.id) || "";
            updatedForm.bankDetails[0].bank.isOther = (data && data.bank && data.bank.others) || false;
            updatedForm.bankDetails[0].otherBank.value = (data && data.bank && data.bank.others && data.name) || false;

        } else {
            let newObj = DefaultBankEvalFormObj();
            newObj.bank.elementConfig.options = updatedForm.bankDetails[0].bank.elementConfig.options;
            newObj.id = (data && data.id) || '';
            newObj.bank.value = (data && data.bank && data.bank.id) || "";
            newObj.bank.isOther = (data && data.bank && data.bank.others) || false;
            newObj.otherBank.value = (data && data.name) || "";
            updatedForm.bankDetails.push(newObj);
        }
        this.setState({ evaluationForm: updatedForm });
    };



    addRemoveEvalBankObj = (e, objKey, btnType, index) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        if (btnType === "More") {
            let newObj = DefaultBankEvalFormObj();
            newObj.bank.elementConfig.options =
                updatedForm[objKey][0].bank.elementConfig.options;
            updatedForm[objKey].push(newObj);
        } else {
            updatedForm[objKey].splice(index, 1);
        }
        this.setState({ evaluationForm: updatedForm });
    }

    addRemoveEvalPincodesObj = (e, objKey, btnType, index) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        if (btnType === "More") {
            let newObj = DefaultPincodesFormObj();
            newObj.pinCodes.elementConfig.options = updatedForm[objKey][0].pinCodes.elementConfig.options;
            updatedForm[objKey].push(newObj);
        } else {
            updatedForm[objKey].splice(index, 1);
        }
        updatedForm.activeSubscriber.value = "";
        this.setState({ evaluationForm: updatedForm });
    }

    addRemoveEvalObj = (e, objKey, btnType, index) => {
        const { evaluationForm } = this.state;
        const updatedForm = { ...evaluationForm };
        if (btnType === "More") {
            let newObj = currentBusinessAssociationEvalForm();
            newObj.curAssoc.selectConfig.elementConfig.options =
                updatedForm[objKey][0].curAssoc.selectConfig.elementConfig.options;
            updatedForm[objKey].push(newObj);
        } else {
            updatedForm[objKey].splice(index, 1);
        }
        this.setState({ evaluationForm: updatedForm });
    };

    validateIntentFormFields = (selectedForm, formType, buttonType) => {
        const validateObj = {};
        const formKeys = Object.keys(selectedForm);
        if(selectedForm.pinCode && selectedForm.pinCode.elementConfig && selectedForm.pinCode.elementConfig.options){
            const pincodeList = selectedForm.pinCode.elementConfig.options;
            let pincodeListArray = pincodeList.map(singlePincode => singlePincode.label);
            pincodeListArray.shift();
            validateObj.pincodeListArray = pincodeListArray;
        }
        if(buttonType && !buttonType.validate){
            return true;
        }
        formKeys.forEach(key => {
            if (key === "gst") {
                validateObj[key] = {
                    value: selectedForm[key].value,
                    isGst: selectedForm[key].radioConfig[0].elementConfig.checked
                };
            } else if (key === "existingRelationship") {
                const radioValue =
                    selectedForm[key].radioConfig[0].elementConfig.checked;
                validateObj[key] = {
                    value: selectedForm.selectInputConfig,
                    isRequired: radioValue
                };
            } else if (key === "currentBusinessSelectInputConfig") {
                validateObj[key] = {
                    value: selectedForm.currentBusinessSelectInputConfig
                };
            } else {
                validateObj[key] = selectedForm[key].value;
            }
        });
        const formStatus = FormValidator.validateIntentForm(validateObj);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...selectedForm };
            formKeys.forEach(key => {
                if (key === "existingRelationship") {
                    const { existingRelationshipObj } = errorMessages;
                    for (let i = 0; i < existingRelationshipObj.length; i++) {
                        if (
                            existingRelationshipObj[i].message &&
                            existingRelationshipObj[i].message !== ""
                        ) {
                            updatedForm.selectInputConfig[i].valid = false;
                            updatedForm.selectInputConfig[i].errorMessage =
                                existingRelationshipObj[i].message;
                        } else {
                            updatedForm.selectInputConfig[i].valid = true;
                            updatedForm.selectInputConfig[i].errorMessage = "";
                        }
                    }
                } else if (key === "currentBusinessSelectInputConfig") {
                    const { currentBusinessSelectInputConfigObj } = errorMessages;
                    for (let i = 0; i < currentBusinessSelectInputConfigObj.length; i++) {
                        if (
                            currentBusinessSelectInputConfigObj[i].message &&
                            currentBusinessSelectInputConfigObj[i].message !== ""
                        ) {
                            updatedForm.currentBusinessSelectInputConfig[i].valid = false;
                            updatedForm.currentBusinessSelectInputConfig[i].errorMessage =
                                currentBusinessSelectInputConfigObj[i].message;
                        } else {
                            updatedForm.currentBusinessSelectInputConfig[i].valid = true;
                            updatedForm.currentBusinessSelectInputConfig[i].errorMessage = "";
                        }
                    }
                } else {
                    const inputKey = `${key}Message`;
                    const errorMessage = errorMessages[inputKey];
                    if (errorMessage && errorMessage !== "") {
                        updatedForm[key].valid = false;
                        updatedForm[key].errorMessage = errorMessage;
                    } else {
                        updatedForm[key].valid = true;
                        updatedForm[key].errorMessage = "";
                    }
                }
            });
            this.setState({ [formType]: updatedForm, formErrorMessage: "" });
            return false;
        } else {
            return true;
        }
    };

    validateEvaluationFormFields = (selectedForm, formType, buttonType) => {
        const validateObj = {};
        const formKeys = Object.keys(selectedForm);
        if(buttonType && !buttonType.validate){
            return true;
        }
        formKeys.forEach(key => {
            if (key === "currentBusinessAssociation") {
                validateObj[key] = {
                    value: selectedForm.currentBusinessAssociation
                };
            }
            else if (key === "bankDetails") {
                validateObj[key] = {
                    value: selectedForm.bankDetails
                };
            } else if (key === "prevExperience" || key === "percentageShare" || key === "propertyBackground" || key === "isTSBBPresence" || key === "isStoreOnRoad" || key === "ageOfBuilding" || key === "activeCableSubscriber" || key === "isFamilyInDTH") {
                validateObj[key] = {
                    value: selectedForm[key]
                };
            }
            else {
                validateObj[key] = selectedForm[key].value;
            }
        });
        const validateFormKeys = Object.keys(validateObj);
        validateFormKeys.forEach(key => {
            if(selectedForm[key].isGradeAvailable) {
                const disabledFieldOption = selectedForm[key].elementConfig.options.filter(option => option.value == selectedForm[key].value && option.disabledFields != null);
                if(disabledFieldOption.length > 0) {
                    const disabledFields = disabledFieldOption[0].disabledFields.split(',');
                    disabledFields.forEach(field => {
                        validateObj[field] = 'test value';
                    })
                }
            }
            if(key === 'briefDescription' && selectedForm['briefDescription'].value != 4) {
                validateObj['otherDescription'] = 'test value';
            }
        })
        const formStatus = selectedForm.storeEnable ? FormValidator.validateStoreForm(validateObj) :FormValidator.validateEvaluationForm(validateObj);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...selectedForm };
            formKeys.forEach(key => {
                if (key === "currentBusinessAssociation") {
                    const { currentBusinessAssociationObj } = errorMessages;
                    for (let i = 0; i < currentBusinessAssociationObj.length; i++) {
                        if (
                            currentBusinessAssociationObj[i].message &&
                            currentBusinessAssociationObj[i].message !== ""
                        ) {
                            updatedForm.currentBusinessAssociation[i].curAssoc.valid = false;
                            updatedForm.currentBusinessAssociation[i].curAssoc.errorMessage =
                                currentBusinessAssociationObj[i].message;
                        } else {
                            updatedForm.currentBusinessAssociation[i].curAssoc.valid = true;
                            updatedForm.currentBusinessAssociation[i].curAssoc.errorMessage = "";
                        }
                    }
                }else if(key === "prevExperience" || key === "percentageShare" || key === "propertyBackground" || key === "isTSBBPresence" || key === "isStoreOnRoad" || key === "ageOfBuilding" || key === "activeCableSubscriber") {
                    // const { prevExperience, percentageShare, propertyBackground, isTSBBPresence, isStoreOnRoad, ageOfBuilding, activeCableSubscriber } = errorMessages;
                    if (errorMessages[key] && errorMessages[key] !== "") {
                        updatedForm[key].valid = false;
                        updatedForm[key].errorMessage = errorMessages[key];
                    } else {
                        updatedForm[key].valid = true;
                        updatedForm[key].errorMessage = "";
                    }
                }
                else if (key === "bankDetails") {
                    const { bankDetailsObj } = errorMessages;
                    for (let i = 0; i < bankDetailsObj.length; i++) {
                        const curBankDetailsObj = bankDetailsObj[i];
                        const updatedBankDetailsObj = updatedForm.bankDetails[i];
                        Object.keys(bankDetailsObj[i]).forEach((key, j)=>{
                            if (curBankDetailsObj[key] &&
                                curBankDetailsObj[key].message &&
                                curBankDetailsObj[key].message !== ""
                            ) {
                                updatedBankDetailsObj[key].valid = false;
                                updatedBankDetailsObj[key].errorMessage =
                                curBankDetailsObj[key].message;
                            } else {
                                updatedBankDetailsObj[key].valid = true;
                                updatedBankDetailsObj[key].errorMessage = "";

                            }
                        })
                    }
                }
                else {
                    const inputKey = `${key}Message`;
                    const errorMessage = errorMessages[inputKey];
                    if (errorMessage && errorMessage !== "") {
                        updatedForm[key].valid = false;
                        updatedForm[key].errorMessage = errorMessage;
                    }
                }
            });
            this.setState({ [formType]: updatedForm, formErrorMessage: "" });
            return false;
        } else {
            return true;
        }
    };

    validateActiveSubscriberField = (selectedForm, formType) => {
        const validateObj = {};
        const formKeys = Object.keys(selectedForm);
        formKeys.forEach(key => {
            if (key === "pinCodes") {
                validateObj[key] = {
                    value: selectedForm.pinCodes
                };
            }
        });
        const formStatus = FormValidator.validateActiveSubscriber(validateObj);
        const { pinCodesStatus } = formStatus;
        if (formStatus.validationStatus) {
            const updatedForm = { ...selectedForm };
            formKeys.forEach(key => {
                if (key === "pinCodes") {
                    for(let i=0; i<updatedForm[key].length; i++) {
                        if (pinCodesStatus[i] && pinCodesStatus[i].message !== "") {
                            updatedForm.pinCodes[i].pinCodes.valid = false;
                            updatedForm.pinCodes[i].pinCodes.errorMessage = pinCodesStatus[i].message;
                        } else {
                            updatedForm.pinCodes[i].pinCodes.valid = true;
                            updatedForm.pinCodes[i].pinCodes.errorMessage = "";
                        }
                    }
                }
            });
            
            this.setState({ [formType]: updatedForm, formErrorMessage: "" });
            return formStatus.validationStatus;
        } else {
            return false;
        }
    };

    validateHardCopyFormFields = (selectedForm, formType, buttonType) => {
        const validateObj = {};
        const formKeys = Object.keys(selectedForm);
        if(buttonType && !buttonType.validate){
            return true;
        }
        formKeys.forEach(key => {
            if (key === 'hardCopyUpload') {
                validateObj[key] = selectedForm[key].docList;
            } else {
                validateObj[key] = selectedForm[key].value;
            }

        });
        const noTermValidate = () => {
            const isRoleCategory = localStorage.getItem("roleCategory");
            const roleCategory = isRoleCategory && JSON.parse(isRoleCategory);
            return (roleCategory !== ROLES_CATEGORY.PARTNER);
        }
        const formStatus = FormValidator.validateHardCopyForm(validateObj, noTermValidate());
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...selectedForm };
            formKeys.forEach(key => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== "") {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
                else {
                    updatedForm[key].valid = true;
                    updatedForm[key].errorMessage = '';
                }
            });
            this.setState({ [formType]: updatedForm, formErrorMessage: "" });
            return false;
        } else {
            return true;
        }
    };

    validateSoftCopyFormFields = (selectedForm,buttonType) => {
        const { serverPartnerObj: {firm: { firmType, warehouseAvailable }}} = this.state;
        const validateObj = {};
        if(selectedForm.pinCode && selectedForm.pinCode.elementConfig && selectedForm.pinCode.elementConfig.options){
            const pincodeList = selectedForm.pinCode.elementConfig.options;
            let pincodeListArray = pincodeList.map(singlePincode => singlePincode.label);
            pincodeListArray.shift();
            validateObj.pincodeListArray = pincodeListArray;
        }
        const formKeys = Object.keys(selectedForm);
        if(buttonType && !buttonType.validate){
            return true;
        }
        formKeys.forEach(key => {
            if (['partnerDetails', 'bankDetails', 'uploadDocumentsTemplate'].includes(key)) {
                validateObj[key] = selectedForm[key];
            } else {
                validateObj[key] = selectedForm[key].value;
            }
        });
        const formStatus = FormValidator.validateSoftCopyForm(validateObj, firmType, warehouseAvailable);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...selectedForm };
            formKeys.forEach(key => {
                if (key === "partnerDetails") {
                    const { partnerDetailsObj } = errorMessages;
                    for (let i = 0; i < partnerDetailsObj.length; i++) {
                        const curPartnerObj = partnerDetailsObj[i];
                        const updatedPartnerObj = updatedForm.partnerDetails[i];
                        Object.keys(curPartnerObj).forEach((key, i) => {
                            if (curPartnerObj[key] && curPartnerObj[key].message !== "") {
                                updatedPartnerObj[key].valid = false;
                                updatedPartnerObj[key].errorMessage = curPartnerObj[key].message;
                            }
                        })
                    }
                } else if (key === "bankDetails") {
                    const { bankDetailsObj } = errorMessages;
                    for (let i = 0; i < bankDetailsObj.length; i++) {
                        const curBankDetailObj = bankDetailsObj[i];
                        const updatedBankDetailsObj = updatedForm.bankDetails[i];
                        Object.keys(curBankDetailObj).forEach((key, i) => {
                            if (curBankDetailObj[key] && curBankDetailObj[key].message !== "") {
                                updatedBankDetailsObj[key].valid = false;
                                updatedBankDetailsObj[key].errorMessage = curBankDetailObj[key].message;
                            }
                        })
                    }
                } else if (key === "uploadDocumentsTemplate") {
                } else {
                    const inputKey = `${key}Message`;
                    const errorMessage = errorMessages[inputKey];
                    if (errorMessage && errorMessage !== "") {
                        updatedForm[key].valid = false;
                        updatedForm[key].errorMessage = errorMessage;
                    }
                }
            });
            this.setState({ documentsSoftCopyForm: updatedForm, formErrorMessage: "" });
            return false;
        } else {
            return true;
        }
    };

    mapServerExistingPartnerShipsArray = (serverExistingPartnerShipsArray, responseExistingPartnerShipsArray) => {
        let defaultArr = [];
        if (responseExistingPartnerShipsArray && responseExistingPartnerShipsArray.length) {
            for (let i = 0; i < responseExistingPartnerShipsArray.length; i++) {
                if (responseExistingPartnerShipsArray[i].id && serverExistingPartnerShipsArray && serverExistingPartnerShipsArray.length) {
                    let matchedObj = serverExistingPartnerShipsArray.find(item => item.id === responseExistingPartnerShipsArray[i].id) || {};
                    matchedObj = matchedObj ? matchedObj : {};
                    let mergedObj = {};
                    merge(mergedObj, matchedObj, responseExistingPartnerShipsArray[i]);
                    defaultArr.push(mergedObj);
                } else {
                    defaultArr.push(responseExistingPartnerShipsArray[i])
                }
            }
        }
        return defaultArr;
    };
    mapServerBanksArray = (serverBanksArray, responseBanksArray) => {
        let defaultArr = [];
        if (responseBanksArray && responseBanksArray.length) {
            for (let i = 0; i < responseBanksArray.length; i++) {
                if (responseBanksArray[i].id && serverBanksArray && serverBanksArray.length) {
                    let matchedObj = serverBanksArray.find(item => item.id === responseBanksArray[i].id) || {};
                    matchedObj = matchedObj ? matchedObj : {};
                    let mergedObj = {};
                    merge(mergedObj, matchedObj, responseBanksArray[i]);
                    defaultArr.push(mergedObj);
                } else {
                    defaultArr.push(responseBanksArray[i])
                }
            }
        }
        return defaultArr;
    };

    mapServerBusinessAssociationArray = (serverBusinessAssociationArray, responseBusinessAssociationArray) => {
        let defaultArr = [];
        if (responseBusinessAssociationArray && responseBusinessAssociationArray.length) {
            for (let i = 0; i < responseBusinessAssociationArray.length; i++) {
                if (responseBusinessAssociationArray[i].id && serverBusinessAssociationArray && serverBusinessAssociationArray.length) {
                    let matchedObj = serverBusinessAssociationArray.find(item => item.id === responseBusinessAssociationArray[i].id);
                    matchedObj = matchedObj ? matchedObj : {};
                    let mergedObj = {};
                    merge(mergedObj, matchedObj, responseBusinessAssociationArray[i]);

                    defaultArr.push(mergedObj)
                } else {
                    defaultArr.push(responseBusinessAssociationArray[i])
                }
            }
        }
        return defaultArr;
    };

    formSubmit = async (e, formType, buttonType) => {
        e.preventDefault();
        const { intentFormIds, evalFormIds, partnerId, serverPartnerObj, docHardCopyFormIds, storeTemplates } = this.state;
        const formValidator = {
            intentForm: {
                validateFn: this.validateIntentFormFields,
                responseMapper: requestMapperForIntent,
                formIds: intentFormIds
            },
            evaluationForm: {
                validateFn: this.validateEvaluationFormFields,
                responseMapper: requestMapperForEvaluation,
                formIds: { intentFormIds: intentFormIds, evalFormIds: evalFormIds }
            },
            docHardCopyForm: {
                validateFn: this.validateHardCopyFormFields,
                responseMapper: requestMapperForDocHardCopy,
                formIds: docHardCopyFormIds
            },
        };
        const formFn = formValidator[formType];
        this.setState({isLoading: true, formErrorMessage:""});
        let selectedForm = this.state[formType];
        const { openModal } = this.props;
        let filteredForm = {};
        if (formType === 'evaluationForm' && !selectedForm.storeEnable) {         
            for (let field in selectedForm) {
                if(selectedForm[field].formTypeStore !== true) {
                    filteredForm[field] = selectedForm[field];
                }
            }
        } else {
            filteredForm = selectedForm;
        }
        const validateResponse = formFn.validateFn(filteredForm, formType, buttonType);
        if (validateResponse) {
            let responseForm = formFn.responseMapper(filteredForm, formFn.formIds, buttonType, storeTemplates);
            let mergedObj = {};
            merge(mergedObj, { id: partnerId }, serverPartnerObj, responseForm);
            //special handling for arrays;
            if (formType === 'intentForm') {
                mergedObj.existingPartnerShips = this.mapServerExistingPartnerShipsArray(serverPartnerObj.existingPartnerShips, responseForm.existingPartnerShips);
                if (mergedObj.businessDetail) {
                    mergedObj.businessDetail.businessAssociations = this.mapServerBusinessAssociationArray(
                        ((serverPartnerObj.businessDetail && serverPartnerObj.businessDetail.businessAssociations) || []), (responseForm.businessDetail.businessAssociations || []));
                }
            } else if (formType === 'evaluationForm') {
                if (mergedObj.businessDetail) {
                    mergedObj.businessDetail.businessAssociations = this.mapServerBusinessAssociationArray(
                        ((serverPartnerObj.businessDetail && serverPartnerObj.businessDetail.businessAssociations) || []), (responseForm.businessDetail.businessAssociations || []));
                }
                if (mergedObj.firm && mergedObj.firm.banks) {
                    mergedObj.firm.banks = this.mapServerBanksArray(
                        ((serverPartnerObj.firm && serverPartnerObj.firm.banks) || []), (responseForm.firm.banks || []));
                }
            } else if (formType === 'documentsSoftCopyForm') {

            } else if (formType === 'docHardCopyForm') {

            }
            const payload = mergedObj;
            // console.log('payload--- ', JSON.stringify(payload));
            try {
                const response = await commonService.partnerFormSubmit(payload);
                const responseData = interceptedData(response.data);
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({
                        isLoading: false
                    });
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: buttonType.message || responseData.message ,
                        hideCloseButton: true,
                        onCloseCustomFunction: () => this.closeModalGetData(buttonType),
                        isCustomCloseFunction: true
                    });
                } else {
                    this.setState({
                        formErrorMessage: responseData.message,
                        isLoading: false
                    });
                }
            } catch {
                this.setState({
                    formErrorMessage: GlobalConfig.serverError,
                    isLoading: false
                });
            }
        } else {
            this.setState({isLoading: false, formErrorMessage:""}, () => {
                const elem = document.getElementsByClassName("error")[0];
                elem && elem.scrollIntoView({behavior: "smooth"});
            });

        }
    };

    emptyEVDMobileNumber = (inputValue, inputIdentifier, formType) => {
        const selectedForm = this.state[formType];
        const updatedForm = { ...selectedForm };
        const updatedFormElement = { ...updatedForm[inputIdentifier] };
        let value = inputValue;
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = false;
        updatedFormElement.errorMessage = "";

        updatedForm[inputIdentifier] = updatedFormElement;

        this.setState({ [formType]: updatedForm, formErrorMessage:'' });
    }

    verifyEvdOtp = (value)=>{
        this.setState({evdDetailsVerified:value});
    };

    verifyEvdDetails = async (formSubmitPayLoad)=>{
        const {openModal} = this.props;
        let mobileNumber = formSubmitPayLoad.firm.evdPhoneNumber;
        let email= formSubmitPayLoad.firm.evdEmailId;
        const verifyPayload = {
            email: email,
            mobileNumber: mobileNumber,
            name: localStorage.getItem('name')||'User',
            service: "VERIFY",
            lob: this.state.serverPartnerObj.lineOfBusiness.name
        };
        try {
            const response = await commonService.verifyEvdDetails(verifyPayload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false});
                if(responseData.data.alreadyExistsMobileNumber){
                    // this.inputChangedHandler('', 'evdMobileNo','documentsSoftCopyForm');
                    this.emptyEVDMobileNumber('', 'evdMobileNo','documentsSoftCopyForm');
                    this.setState({formErrorMessage: responseData.message});
                    return null
                }
                if(!responseData.data.emailIdOtpSent &&!responseData.data.mobileOtpSent){
                    this.triggerDocumentSoftCopyFormSubmit(formSubmitPayLoad);
                }
                else {
                    let props = {
                        mobileNumber: mobileNumber,
                        email: email,
                        verifyEvdOtp:this.verifyEvdOtp,
                        isEmailToVerify:responseData.data.emailIdOtpSent,
                        isMobileNoToVerify:responseData.data.mobileOtpSent,
                        actionAfterVerification:this.triggerDocumentSoftCopyFormSubmit,
                        actionPayLoad:formSubmitPayLoad,
                        name: localStorage.getItem('name')||'User',
                        lob: this.state.serverPartnerObj.lineOfBusiness.name
                    };
                    openModal(MODALS.VERIFY_EVD_DETAILS_MODAL,{
                       modalProps:props
                    });
                }
            } else {
                this.setState({formErrorMessage: responseData.message, isLoading: false})
            }
        } catch {
            this.setState({formErrorMessage: GlobalConfig.serverError, isLoading: false})
        }
    };

    triggerDocumentSoftCopyFormSubmit = async(payload)=>{
        const {openModal,closeModal} = this.props;
        this.setState({isLoading: true});
        closeModal();
        try {
            const response = await commonService.partnerFormSubmit(payload);
            const responseData = interceptedData(response.data);
            const modalData = payload.allActions.filter(singleAction => singleAction.id === payload.actionId);
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({
                    isLoading: false
                });
                let modalMessage = "";
                if(modalData[0].message === null){
                    modalMessage = responseData.message;
                }
                else{
                    modalMessage = modalData[0].message
                }
                openModal(MODALS.CONFIRMATION_POPUP, {
                    message:  modalMessage,
                    hideCloseButton: true,
                    onCloseCustomFunction: this.closeModalGetData,
                    isCustomCloseFunction: true
                });
            } else {
                this.setState({formErrorMessage: responseData.message, isLoading: false});
            }
        } catch {
            this.setState({formErrorMessage: GlobalConfig.serverError, isLoading: false});
        }
    };

    documentsSoftCopyFormSubmit = async (e,formType,buttonType) => {
        e.preventDefault();
        this.setState({isLoading: true, formErrorMessage:""});
//        const {openModal} = this.props;
        const {
            documentsSoftCopyForm,
            documentSoftCopyIds,
            serverPartnerObj,
        } = this.state;
        let requestObj = requestMapperForDocumentSoftCopy(
            documentsSoftCopyForm,
            documentSoftCopyIds,
            serverPartnerObj,
            buttonType
        );
        let mergedObj = {};
        const validateForm = this.validateSoftCopyFormFields(documentsSoftCopyForm, buttonType);
        if (validateForm) {
            merge(mergedObj, serverPartnerObj, requestObj);
            const payload = mergedObj;
            payload['partnerDocument'] = {...requestObj.partnerDocument};
            payload['partnerDetails']=[...requestObj.partnerDetails];
            payload['firm']['banks']=[...requestObj.firm.banks];
            //not to check evdDetails if saved as draft
            if(buttonType && !buttonType.validate){
                this.triggerDocumentSoftCopyFormSubmit(payload);
            }else{
                this.verifyEvdDetails(payload);
            }

        } else {
            this.setState({isLoading: false, formErrorMessage:""}, () => {
                const elem = document.getElementsByClassName("error");
                const docElem = document.getElementsByClassName("doc-error")[0];

                if(elem.length > 1) {
                    elem[0].scrollIntoView({behavior: "smooth"});
                } else if(docElem) {
                    docElem.scrollIntoView({behavior: "smooth"});
                } else elem[0].scrollIntoView({behavior: "smooth"});
            });
        }
    };

    handleSelect = index => {
        this.setState({
            activeTab: index,
            formErrorMessage: ""
        });
    };

    setDocumentSoftCopyFormData = updatedForm => {
        this.setState({ documentsSoftCopyForm: updatedForm });
    };

    setUploadDocId = id => {
        this.setState({ uploadDocId: id });
    };

    loading = (status, task) =>{
        this.setState({
            isLoading: status,
            isLoadingMessage : task
        })
    }

    documentsArrayFiledChangeHandler = (
        value,
        formElementId,
        formType,
        index,
        obj
    ) => {
        const selectedForm = this.state[formType];
        const {serverBankList} = this.state;
        const updatedForm = { ...selectedForm };
        let updatedFormElement = updatedForm[formElementId][index][obj];
        let updatedFormElementOtherBank = updatedForm[formElementId][index].otherBank;
        let inputValue = value;
        if(formElementId ==="bankDetails" && obj==="bankName"){
            let bankListObj = serverBankList && serverBankList.length &&
                serverBankList.find(item =>  item.id == inputValue);
            updatedFormElement.value = inputValue;
            if(bankListObj && bankListObj.others){
                updatedFormElement.isOther = true;
            }else{
                updatedFormElement.isOther = false;
                updatedFormElementOtherBank.value = '';
                updatedFormElementOtherBank.touched = true;
                updatedFormElementOtherBank.valid = true;
                updatedFormElementOtherBank.errorMessage = "";
            }
        }
        if (value &&
            (obj === "bankAccountNumber" ||
            obj === "ccLimit")
        ) {
            inputValue = value && value.replace(/[^0-9]/g, "");
        }
        updatedFormElement.value = inputValue;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = "";
        updatedForm[formElementId][index][obj] = updatedFormElement;

        this.setState({ [formType]: updatedForm });
    };
    documentSoftCopyUploadRadioHandler = (input, sectionId, objKey, inputId, index) => {
        const { documentsSoftCopyForm } = this.state;
        let updatedForm = { ...documentsSoftCopyForm };
        let updatedElement = {};
        let templateArrSection = updatedForm[objKey].templateArr;
        for (let i = 0; i < templateArrSection.length; i++) {
            if (templateArrSection[i].id === sectionId) {
                updatedElement = templateArrSection[i];
            }
        }
        let updatedRadioElementArr = updatedElement.radioElementArr[index];
        updatedRadioElementArr.radioConfig.forEach(value => {
            value.elementConfig.checked = !value.elementConfig.checked;
            if(updatedElement.rightSectionConfig && updatedElement.rightSectionConfig.hide){
                if(value.labelConfig.label.toLowerCase().includes('no') && value.elementConfig.checked){
                    updatedElement.rightSectionConfig.showText = true;
                }else{
                    updatedElement.rightSectionConfig.showText = false;

                }
            }else{

                if (value.labelConfig.label.toLowerCase().includes('yes') && value.elementConfig.checked) {
                    updatedElement.buttonConfig.isDisabled = false
                } else if (value.labelConfig.label.toLowerCase().includes('no') && value.elementConfig.checked) {
                    updatedElement.buttonConfig.isDisabled = true;
                }
                if (value.labelConfig.label.toLowerCase().includes('2') && value.elementConfig.checked) {
                    updatedElement.rightSectionConfig.checked = false;
                    updatedElement.rightSectionConfig.disabled = true;
                } else if (value.labelConfig.label.toLowerCase().includes('1') && value.elementConfig.checked) {
                    updatedElement.rightSectionConfig.disabled = false;
                }
            }

        });
        updatedForm[objKey].templateArr = templateArrSection;
        this.setState({ documentsSoftCopyForm: updatedForm, formIsUpdated: true });
    };
    documentSoftCopyCheckHandler = (input, sectionId, objKey, inputId) => {
        const { documentsSoftCopyForm } = this.state;
        let updatedForm = { ...documentsSoftCopyForm };
        let updatedElement = {};
        let templateArrSection = updatedForm[objKey].templateArr;
        for (let i = 0; i < templateArrSection.length; i++) {
            if (templateArrSection[i].id === sectionId) {
                updatedElement = templateArrSection[i];
            }
        }
        updatedElement.rightSectionConfig.checked = !updatedElement.rightSectionConfig.checked;
        updatedForm[objKey].templateArr = templateArrSection;
        this.setState({ documentsSoftCopyForm: updatedForm });
    };

    setFormIsUpdated = (value) => {
        this.setState({ formIsUpdated: value });
    };

    getFormConfig(formData) {
        let updatedFormConfig = Object.assign({}, this.state.formConfig);
        updatedFormConfig.intentForm = {
            editable: formData && formData.intentForm && formData.intentForm.editable,
            enable: formData && formData.intentForm && formData.intentForm.enable,
            buttonArray: formData && formData.intentForm && formData.intentForm.allActions.sort((first, second) => {
                return first.priority.localeCompare(second.priority)
            })
        }
        updatedFormConfig.documentForm = {
            editable: formData && formData.documentForm && formData.documentForm.editable,
            enable: formData && formData.documentForm && formData.documentForm.enable,
            buttonArray: formData && formData.documentForm && formData.documentForm.allActions.sort((first, second) => {
                return first.priority.localeCompare(second.priority)
            })
        }
        updatedFormConfig.evaluationForm = {
            editable: formData && formData.evaluationForm && formData.evaluationForm.editable,
            enable: formData && formData.evaluationForm && formData.evaluationForm.enable,
            buttonArray: formData && formData.evaluationForm && formData.evaluationForm.allActions.sort((first, second) => {
                return first.priority.localeCompare(second.priority)
            })
        }
        updatedFormConfig.originalDocument = {
            editable: formData && formData.originalDocument && formData.originalDocument.editable,
            enable: formData && formData.originalDocument && formData.originalDocument.enable,
            buttonArray: formData && formData.originalDocument && formData.originalDocument.allActions.sort((first, second) => {
                return first.priority.localeCompare(second.priority)
            }),
            sendTo: formData && formData.originalDocument && formData.originalDocument.sendTo,
        };
        this.setState({ formConfig: updatedFormConfig });
    }

    render() {
        const {windowWidth, openModal} = this.props;
        const isMobile = window.innerWidth <= RESPONSIVE.MOBILE_VIEW || window.screen.width <= RESPONSIVE.MOBILE_VIEW || windowWidth < RESPONSIVE.MOBILE_VIEW;
        const {
            isDocumentFormShow,
            activeTab,
            intentForm,
            isLoading,
            isLoadingMessage,
            formErrorMessage,
            evaluationForm,
            docHardCopyForm,
            documentsSoftCopyForm,
            uploadDocId,
            partnerDocuments,
            uploadedDocumentsRadioValueKeys,
            formIsUpdated,
            formConfig,
            // partnerId,
            applicationAssignedTo,
            progressStatus,
            applicationId,
            serverPartnerObj,
            isSecondApplication
        } = this.state;
        const {
            handleSelect,
            inputChangedHandler,
            radioChangeHandler,
            addRemoveObj,
            selectHandler,
            selectInputConfigHandler,
            evalFormAssocFieldChageHandler,
            evalFormBankChageHandler,
            formSubmit,
            addRemoveEvalObj,
            setDocumentSoftCopyFormData,
            setUploadDocId,
            documentsArrayFiledChangeHandler,
            documentSoftCopyUploadRadioHandler,
            documentSoftCopyCheckHandler,
            documentsSoftCopyFormSubmit,
            fileHandler,
            setFormIsUpdated,
            addRemoveEvalBankObj,
            termsConditionChangeHandler,
            loading,
            storeSelectHandler,
            addRemoveEvalPincodesObj,
            evalFormPincodesChageHandler,
            evalFormCalculatePincodesHandler
        } = this;
        return (
            <div className="partner-form-container row no-gutters">
                {isLoading && <Loader task = { isLoadingMessage }/>}
                <div className="col-lg-3 col-xs responsive-order">
                    <h2 className="heading mb10">Application Form</h2>
                    <div className="sub-heading pb25">
                        Fill the application form carefully to become an Applicant
                    </div>
                    {
                        isMobile ? <MobileViewLeftSidebar formConfig={formConfig} handleSelect={handleSelect} activeTab={activeTab} /> :
                         <LeftSidebar formConfig={formConfig} handleSelect={handleSelect} activeTab={activeTab}/>
                    }
                </div>

                <div className="col-lg-6 col-xs responsive-order">
                    <div className="form-view">
                        {activeTab === 0 ? (
                            <IntentForm
                                intentForm={intentForm}
                                formConfig={formConfig['intentForm']}
                                formErrorMessage={formErrorMessage}
                                inputChangedHandler={inputChangedHandler}
                                radioChangeHandler={radioChangeHandler}
                                addRemoveObj={addRemoveObj}
                                selectHandler={selectHandler}
                                selectInputConfigHandler={selectInputConfigHandler}
                                formSubmit={formSubmit}
                                termsConditionChangeHandler={termsConditionChangeHandler}
                                openModal={openModal}
                                isSecondApplication={isSecondApplication}
                            />
                        ) : null}
                        {activeTab === 1 ? (
                            <EvaluationForm
                                evaluationForm={evaluationForm}
                                formConfig={formConfig['evaluationForm']}
                                inputChangedHandler={inputChangedHandler}
                                radioChangeHandler={radioChangeHandler}
                                selectHandler={selectHandler}
                                evalFormAssocFieldChageHandler={evalFormAssocFieldChageHandler}
                                evalFormBankChageHandler={evalFormBankChageHandler}
                                addRemoveObj={addRemoveEvalObj}
                                addRemoveBankObj={addRemoveEvalBankObj}
                                formErrorMessage={formErrorMessage}
                                formSubmit={formSubmit}
                                termsConditionChangeHandler={termsConditionChangeHandler}
                                storeSelectHandler={storeSelectHandler}
                                addRemovePincodesObj={addRemoveEvalPincodesObj}
                                evalFormPincodesChageHandler={evalFormPincodesChageHandler}
                                evalFormCalculatePincodesHandler={evalFormCalculatePincodesHandler}
                                isSecondApplication={isSecondApplication}

                            />
                        ) : null}
                        { (activeTab === 2 && isDocumentFormShow) ? (
                            <DocumentSoftCopy
                                documentsSoftCopyForm={documentsSoftCopyForm}
                                formConfig={formConfig['documentForm']}
                                setDocumentSoftCopyFormData={setDocumentSoftCopyFormData}
                                setUploadDocId={setUploadDocId}
                                loading={loading}
                                uploadDocId={uploadDocId}
                                inputChangedHandler={inputChangedHandler}
                                radioChangeHandler={radioChangeHandler}
                                addRemoveObj={addRemoveObj}
                                selectHandler={selectHandler}
                                selectInputConfigHandler={selectInputConfigHandler}
                                arrayFiledChangeHandler={documentsArrayFiledChangeHandler}
                                formSubmit={documentsSoftCopyFormSubmit}
                                uploadRadioHandler={documentSoftCopyUploadRadioHandler}
                                documentSoftCopyCheckHandler={documentSoftCopyCheckHandler}
                                partnerDocuments={partnerDocuments}
                                uploadedDocumentsRadioValueKeys={uploadedDocumentsRadioValueKeys}
                                setFormIsUpdated={setFormIsUpdated}
                                formIsUpdated={formIsUpdated}
                                termsConditionChangeHandler={termsConditionChangeHandler}
                                formErrorMessage={formErrorMessage}
                                openModal={openModal}
                                serverPartnerObj={serverPartnerObj}
                            />
                        ) : null}
                        {activeTab === 3 ? (
                            <DocumentHardCopy
                                docHardCopyForm={docHardCopyForm}
                                formConfig={formConfig['originalDocument']}
                                inputChangedHandler={inputChangedHandler}
                                formErrorMessage={formErrorMessage}
                                formSubmit={formSubmit}
                                fileHandler={fileHandler}
                                termsConditionChangeHandler={termsConditionChangeHandler}
                            />
                        ) : null}
                    </div>
                </div>

                <div className="col-lg-3 col-xs responsive-order">
                    <StatusDetails
                    status={progressStatus}
                    id={applicationId}
                    assignedTo={applicationAssignedTo}
                    />
                </div>
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(withRouter(ApplicationForm));
